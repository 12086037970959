import React from 'react'

//style
import "../../style/main.scss"
import "../../style/sectionsStyle/section3.scss"


function section3() {
  return (
<section className='section3'>
    <h2>АРЕНДА КИНОТЕАТРА</h2>
    <p>Зал кинотеатра сова синема включает в себя всё необходимое для комфортного просмотра любимого фильма. Цены не кусаются, вы можете выбрать любой сеанс в удобное для вас время. Наш вип кинотеатр встретит вас с радушием и порадует необычным интерьером, наполненным романтикой и уютом. Свидание в кинотеатре - это тренд современного времени. Если вам надоели обычные кинотеатры, вы можете взять в аренду кинотеатр для двоих и наслаждаться показом в романтической и спокойной обстановке.</p>
   </section>  )
}

export default section3